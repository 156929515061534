import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import { Link } from 'gatsby'

const ImageSection = ({ imgPath, firstLine, secondLine, buttonText, buttonLink }) => (
  <div
    className='w-full flex justify-center items-center p-10 lg:p-48 flex-col'
    style={{ background: `url(${imgPath}) no-repeat center`, backgroundSize: 'cover' }}
  >
    <h2 className='text-white-gefa text-4xl underline text-center max-w-screen-xl' style={{ textShadow: '0px 0px 15px #000' }}>{firstLine}</h2>
    <h2 className='text-white-gefa mt-6 text-2xl underline text-center max-w-screen-xl' style={{ textShadow: '0px 0px 15px #000' }}>{secondLine}</h2>
    <div className='mt-10 text-white-gefa'>
      <Link to={buttonLink}>
        <Button text={buttonText} colorClass='white-gefa uppercase' invertedColorClass='green-gefa' />
      </Link>
    </div>
  </div>
)

ImageSection.propTypes = {
  imgPath: PropTypes.string.isRequired,
  firstLine: PropTypes.string.isRequired,
  secondLine: PropTypes.string.isRequired
}

export default ImageSection
