import React, { Component } from 'react'
import videoMP4 from '../../videos/video.mp4'
import PropTypes from 'prop-types'

class VideoHeading extends Component {
  constructor (props) {
    super(props)
    this.state = {
      height: null
    }
    this.videoRef = React.createRef()
  }

  componentDidMount () {
    if (this.videoRef) {
      const video = this.videoRef.current
      video.play()
    }

    const setHeight = (event) => {
      console.log(window.innerHeight)
      this.setState({ height: window.innerHeight - 80 })
    }

    setHeight()
    // window.onresize = setHeight
  }

  render () {
    const { titleFirstLine, titleSecondLine } = this.props
    return (
      <div style={{ height: this.state.height }} className='flex justify-center items-center bg-black-gefa p-0 overflow-hidden'>
        <h1 className='text-white-gefa absolute underline text-6xl md:text-6xl lg:text-7xl font-semibold text-center'>{titleFirstLine}<br />{titleSecondLine}</h1>
        <video ref={this.videoRef} autoPlay playsInline muted loop className='object-cover h-full w-full'>
          <source src={videoMP4} type='video/mp4' />
        </video>
      </div>
    )
  }
}

VideoHeading.propTypes = {
  titleFirstLine: PropTypes.string.isRequired,
  titleSecondLine: PropTypes.string.isRequired
}

export default VideoHeading
