import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Image from '../../components/image'
import Seo from '../../components/seo'
import VideoHeading from '../../components/VideoHeading'
import Section from '../../components/Section'
import ImageSection from '../../components/ImageSection'
import Button from '../../components/Button'
import ImageLink from '../../components/ImageLink'
import generateLink from '../../utils/generateLink'
import Zoom from 'react-reveal/Zoom'
import './index.css'
import ImageForSection from '../../images/foto/optimized/assemblaggio_mocio_2.jpg'
import qualityImage from '../../images/foto/optimized/quality.jpg'
import applicationImage from '../../images/originals/optimized/application.jpg'

const IndexPage = ({ location, langKey }) => {
  const { site } = useStaticQuery(
    graphql` query {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          }
          pagesData {
            index {
              titleFirstLine {
                it en de
              }
              titleSecondLine {
                it en de
              }
              firstSectionTitle {
                it en de
              }
              firstSectionContent {
                it en de
              }
              firstSectionButtonText {
                it en de
              }
              imageSectionTitleFirstLine {
                it en de
              }
              imageSectionTitleSecondLine {
                it en de
              }
              imageSectionButtonText {
                it en de
              }
              twoColumnSectionFirstText {
                it en de
              }
              twoColumnSectionSecondText {
                it en de
              }
              twoColumnSectionFirstImageText {
                it en de
              }
              twoColumnSectionSecondImageText {
                it en de
              }
            }
            services {
              sideSection {
                firstBlockLinks {
                  text {
                    de
                    en
                    it
                  }
                  path
                }
              }
            } 
          }
        }
      }
    }
      `
  )

  const {
    titleFirstLine,
    titleSecondLine,
    firstSectionTitle,
    firstSectionContent,
    firstSectionButtonText,
    imageSectionTitleFirstLine,
    imageSectionTitleSecondLine,
    imageSectionButtonText,
    twoColumnSectionFirstText,
    twoColumnSectionFirstImageText,
    twoColumnSectionSecondImageText
  } = site.siteMetadata.pagesData.index

  const {
    sideSection: serviceSideSection
  } = site.siteMetadata.pagesData.services

  const linksForSide = serviceSideSection.firstBlockLinks.map((el) => ({ path: generateLink(langKey, '/services/' + el.path), text: el.text[langKey] }))

  return (
    <>
      <Seo
        title={`${titleFirstLine[langKey]} ${titleSecondLine[langKey]}`}
        lang={langKey}
        urlPath={location.pathname}
      />
      <VideoHeading
        titleFirstLine={titleFirstLine[langKey]}
        titleSecondLine={titleSecondLine[langKey]}
      />
      <Zoom>
        <Section>
          <div className='max-w-screen-xl grid grid-cols-1 md:grid-cols-12 justify-center text-green-gefa m-auto my-6'>
            <div className='hidden md:block col-span-4'>
              <Image imgRef='indexFirstBlockImage' />
            </div>
            <div className='col-span-1 col-start-1 md:col-start-6 md:col-span-6 flex flex-col justify-center px-10 md:px-0'>
              <h2 className='text-4xl md:6.5xl underline inline-block leading-tight'>{firstSectionTitle[langKey]}</h2>
              <p className='font-semibold text-3xl md:text-2xl text-green-gefa mt-3 leading-8'>{firstSectionContent[langKey]}</p>
              <div className='my-6 text-xl'>
                <Link to={generateLink(langKey, '/company')}>
                  <Button text={firstSectionButtonText[langKey]} />
                </Link>
              </div>
            </div>
          </div>
        </Section>
      </Zoom>
      <ImageSection
        imgPath={ImageForSection}
        firstLine={imageSectionTitleFirstLine[langKey]}
        secondLine={imageSectionTitleSecondLine[langKey]}
        buttonText={imageSectionButtonText[langKey]}
        buttonLink={generateLink(langKey, '/services')}
      />
      <Zoom>
        <Section>
          <div className='max-w-screen-xl mb-16 md:mb-24 md:mt-12 grid grid-cols-1 md:grid-cols-2 gap-3 justify-center m-auto'>
            <div className='mb-10 md:mb-0 text-2xl text-black-gefa font-semibold col-span-1 flex justify-center items-center px-10 mb-px-16'>{twoColumnSectionFirstText[langKey]}</div>
            <div className='mb-0 md:mb-0 text-2xl underline col-span-1 flex flex-col justify-center items-left md:items-center px-10'>
              <span>
                {
                  linksForSide.map(
                    (el, i) => (
                      <div key={`side_${i}`} className='font-bold text-3xl leading-tight'>
                        <Link to={el.path} className='hover:text-green-gefa text-3xl underline' title={el.text}>{el.text}</Link>
                      </div>
                    )
                  )
                }
              </span>
            </div>
          </div>
          <div className='max-w-screen-xl grid grid-cols-1 md:grid-cols-2 gap-3 justify-center m-auto'>

            <div className='h-64 col-span-1'>
              <ImageLink
                imgPath={applicationImage}
                text={twoColumnSectionFirstImageText[langKey]}
                link={generateLink(langKey, '/applications')}
              />
            </div>
            <div className='h-64 col-span-1'>
              <ImageLink
                imgPath={qualityImage}
                text={twoColumnSectionSecondImageText[langKey]}
                link={generateLink(langKey, '/quality')}
              />
            </div>
          </div>
        </Section>
      </Zoom>

    </>
  )
}

export default IndexPage
