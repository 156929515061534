import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import './ImageLink.css'

const ImageLink = ({ imgPath, text, link }) => (
  <div
    className='image-link-hover h-full w-full bg-green-gefa text-white-gefa relative overflow-hidden'
  >
    <div className='image-link-blur-target absolute h-full w-full' style={{ background: `url(${imgPath}) center center`, backgroundSize: 'cover' }} />
    <Link to={link} title={text}>
      <span className='image-link-hover text-3xl absolute h-full w-full flex justify-center items-center underline cursor-pointer'>
        {text}
      </span>
    </Link>
  </div>
)

ImageLink.propTypes = {
  imgPath: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}

export default ImageLink
